@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./style/fonts.css);

@layer {
}
.all-unset {
  all: unset !important;
}
* {
  @apply focus:outline-0 focus-visible:!shadow-none focus-visible:!outline-0 focus-visible:!outline-offset-0 focus-visible:!outline-transparent;
}
body {
  @apply font-matter text-base leading-normal font-normal text-black tracking-[0.1px];
}
.scroll-hide {
  scrollbar-width: none;
}
.scroll-hide::-webkit-scrollbar {
  @apply w-0 h-0;
}
.style-scroll::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}
.style-scroll::-webkit-scrollbar-thumb {
  @apply rounded-full bg-black/50;
}
.style-scroll::-webkit-scrollbar-track {
  @apply rounded-full bg-LightGray;
}
.textarea-disable-resize {
  @apply resize-none;
}
*::-webkit-search-cancel-button {
  @apply hidden;
}
p:empty {
  @apply hidden;
}
img,
a,
button {
  @apply select-none;
}
.wrapper {
  @apply flex flex-wrap;
}
.hashtag-label {
  @apply inline-block mb-3 w-fit bg-LightWood border border-solid border-PrimaryWood/20 text-black text-sm rounded-full px-3 py-2;
}
.fancybox__container {
  @apply !z-[12345];
}

/* BUTTON LINK STYLE */
.btn a svg {
  @apply translate-y-0.5;
}
.btn a {
  @apply inline-flex items-center gap-2 w-fit px-5 pt-3.5 pb-3.5 text-base leading-12 rounded-full border border-solid border-transparent transition-all duration-300;
}
.btn.btn-black a {
  @apply bg-black hover:bg-transparent border-black text-white hover:text-black focus:ring-2 focus:ring-offset-2 focus:ring-black/20;
}
.btn.btn-black-border a {
  @apply bg-transparent hover:bg-black border-black text-black hover:text-white focus:ring-2 focus:ring-offset-2 focus:ring-black/20;
}
.btn.btn-offWhite a {
  @apply bg-LightGray hover:bg-black border-LightGray text-black hover:text-white focus:ring-2 focus:ring-offset-2 focus:ring-black/20;
}

.btn.btn-red a {
  @apply bg-PrimaryRed hover:bg-transparent border-PrimaryRed text-white hover:text-PrimaryRed  focus:ring-2 focus:ring-offset-2 focus:ring-PrimaryRed/20;
}
/* BUTTON LINK STYLE */

.section-title .hashtag-label {
  @apply mb-5;
}
.section-title .small-title {
  @apply text-base uppercase leading-normal text-PrimaryWood tracking-widest font-semibold inline-block mb-3.5;
}
.section-title h2,
.section-title .h2 {
  @apply text-2xl md:text-3xl xl:text-[40px] !leading-12 font-semibold text-black mb-1;
}

.section-title p {
  @apply mt-4 text-base leading-normal text-black/70;
}
.swiper .swiper-wrapper {
  @apply !h-fit;
}
.swiper .swiper-pagination {
  @apply !static mt-30px flex gap-2 items-center justify-center pb-2;
}
.swiper .swiper-pagination-bullet {
  @apply !w-2 !h-2 !opacity-100 !bg-LightWood rounded-full transition-all duration-300 cursor-pointer;
}
.swiper .swiper-pagination-bullet-active {
  @apply !bg-PrimaryWood shadow-PrimaryRing;
}

section:not(#shadow-host-companion) {
  @apply py-10 1200:py-12 1300:py-14 1600:py-70px;
}
section:not(#shadow-host-companion).fill {
  @apply my-10 1200:my-12 1300:my-14 1600:my-70px;
}

/* TAB  */
.tab-list {
  @apply mb-14;
}
.tab-list ul .button {
  @apply rounded-full min-h-[50px] hover:bg-black hover:text-white hover:border-black;
}
.tab-list ul {
  @apply flex flex-wrap gap-2.5;
}
/* TAB  */

/* FILTER */
.filter-wrap {
  @apply relative;
}
.filter-list {
  @apply absolute top-full right-0;
}
.filter-list .inner {
  @apply min-w-[170px] max-w-[250px] max-h-[250px] overflow-auto bg-white shadow-lg shadow-black/10;
}
.filter-list .checkbox-wrap {
  @apply hover:bg-LightGray py-3 px-5 border-b border-solid border-LightWood/50 last:border-b-0;
}
/* FILTER */

.content-base {
  @apply bg-white p-5 rounded-5px border border-solid border-gray-200;
}

/* FOR LAZY STYLE */
.lazy,
.lazy2 {
  @apply relative max-w-full w-full;
}
.lazy2::before,
.lazy::before {
  @apply absolute content-[""] w-full h-full bg-200 left-0 top-0 bg-lazyGradient animate-lazy rounded-md z-[1];
}
.lazy2::before {
  @apply bg-lazyGradient2;
}

/* STEP STYLE */
.student-page-header {
  @apply flex justify-between items-center pt-5 md:mb-2 xl:mb-9;
}
.admin-content main > *:not(.page-header) {
  @apply px-5;
}
.header-profile-menu::before {
  @apply content-[''] absolute right-4 -top-2.5 border-x-[12px] border-x-transparent border-b-[12px] border-b-white;
}
.header-notification-list-wrap::before {
  @apply content-[''] absolute right-4 -top-2.5 border-x-[12px] border-x-transparent border-b-[12px] border-b-white;
}
.filter-title {
  @apply pb-2.5 border-b border-solid border-LightGray flex justify-between;
}
.filter-title p {
  @apply text-xl leading-12 font-medium text-black;
}
.filter-title .filter-count {
  @apply text-PrimaryWood bg-LightWood inline-block text-sm px-1.5 leading-none rounded-full py-0.5;
}
.filter-title .clear-filter {
  @apply text-sm text-PrimaryRed hover:opacity-70 uppercase hover:underline;
}

.step-wrapper {
  @apply flex justify-center mb-30px;
}
.step-item::after {
  @apply w-24 block h-0.5 absolute top-5 right-2 bg-LightWood content-[''] mx-2.5 rounded-full;
}
.step-item {
  @apply pr-32 last:pr-0 relative last:after:hidden;
}
.step-item.completed .step-item__number {
  @apply bg-primary text-white border-black text-transparent;
}
.step-item.active .step-item__number {
  @apply text-black border-black bg-LightGray relative;
}
.step-item.completed .step-item__number::before {
  @apply absolute content-[''] w-3.5 h-2 border-l-2 border-b-2 border-white border-solid -rotate-45;
}
.step-wrapper.primary .step-item.active .step-item__number {
  @apply bg-PrimaryWood border-PrimaryWood;
}
.step-item__number {
  @apply flex items-center justify-center text-PrimaryWood font-medium w-12 h-12 rounded-full border border-solid border-PrimaryWood;
}
.step-item__number svg {
  @apply w-7 h-7;
}
.step-item__languages {
  @apply text-PrimaryWood block text-sm leading-12 mt-3.5;
}
.step-item.active .step-item__languages {
  @apply text-black;
}

/*  */
.row {
  @apply flex flex-wrap gap-5;
}
.row .left-part,
.row .right-part {
  @apply w-[calc(50%_-_10px)];
}

.input-label {
  @apply text-base text-black/60 mb-2 leading-tight block;
}

.btn-wrap {
  @apply flex justify-end gap-4 mt-4;
}

/* USER PROFILE IN TABLE */
.user-profile-data {
  @apply flex gap-2 items-center;
}
.user-profile-image {
  @apply w-8 h-8 rounded-full;
}
.user-profile-image img {
  @apply w-full h-full rounded-full object-cover;
}
.user-profile-name {
  @apply max-w-[calc(100%_-_32px)] w-full flex-1 text-lg font-light text-black h-fit whitespace-nowrap;
}
/* USER PROFILE IN TABLE */
body > div:last-child {
  @apply z-5;
}

/*  */
.topic-option {
  @apply ml-auto relative;
}
.topic-option ul {
  @apply bg-white rounded-10px drop-shadow-lg absolute left-full top-0 translate-x-4 opacity-0 invisible pointer-events-none transition-all duration-300;
}
.topic-option ul.adminRole {
  @apply right-9 left-unset;
}
.topic-option ul::after {
  @apply absolute content-[''] w-full h-full top-0 right-5 -z-1;
}

.topic-option:hover ul {
  @apply pointer-events-auto opacity-100 visible translate-x-1;
}

.topic-option ul::before {
  @apply absolute content-[''] -left-2 top-2.5 border-y-[8px] border-r-[8px] border-solid border-transparent border-r-white;
}
.topic-option ul.adminRole::before {
  @apply left-unset -right-2 border-l-white border-l-8 border-r-0;
}
.topic-option ul li:first-child button,
.topic-option ul li:first-child span {
  @apply rounded-t-10px;
}
.topic-option ul li:last-child button,
.topic-option ul li:last-child span {
  @apply rounded-b-10px;
}

.topic-option ul button,
.topic-option ul li > span {
  @apply flex w-full items-center gap-1.5 p-2.5 cursor-pointer hover:bg-LightWood transition-all duration-300;
}
.topic-option ul button svg,
.topic-option ul li > span svg {
  @apply w-18px h-18px text-PrimaryWood;
}
.topic-option ul li:last-child svg {
  @apply text-PrimaryRed;
}
.topic-option ul li span {
  @apply text-sm text-black whitespace-nowrap select-none;
}

/* REFER SECTION */
.refer-sec .section-title ul {
  @apply list-decimal pl-6 flex flex-col gap-3;
}
.refer-sec .section-title ul li {
  @apply text-base font-medium text-black/70;
}
/* REFER SECTION */

/* GIFT CARD SEC */

.gift-card-banner .left-part ul {
  @apply mt-6 flex flex-col gap-y-3;
}
.gift-card-banner .left-part ul li {
  @apply text-black/70 text-base pl-30px relative;
}
.gift-card-banner .left-part ul li::before {
  @apply absolute content-[''] w-5 h-5 rounded-full bg-PrimaryWood left-0;
}
.gift-card-banner .left-part ul li::after {
  @apply absolute content-[''] w-[5px] h-2.5 border-r-2 border-b-2 border-solid border-white left-2 top-1 rotate-45;
}
/* GIFT CARD SEC */

/* GIFT CARD DETAILS */
.product-details .left-part ul li::before {
  @apply absolute content-[''] w-5 h-5 rounded-full bg-PrimaryWood left-0;
}
.product-details .left-part ul li::after {
  @apply absolute content-[''] w-[5px] h-2.5 border-r-2 border-b-2 border-solid border-white left-2 top-1 rotate-45;
}
/* GIFT CARD DETAILS */

/* PROFILE SIDEBAR */
.sidebar-link-item.active a {
  @apply text-black;
}
.sidebar-link-item.active a::before {
  @apply absolute -right-0.5 content-[''] top-0 w-1 h-full bg-black;
}
/* PROFILE SIDEBAR */

/* DISCOUNT DATE */
.discount-date-select .data-picker-wrap {
  @apply bg-LightGray rounded-5px flex gap-1 items-center px-0.5 py-0.5 transition-all duration-300 text-PrimaryWood;
}
.discount-date-select .react-datepicker__tab-loop {
  @apply absolute;
}
.react-datepicker-popper {
  @apply !z-3;
}
/* DISCOUNT DATE */

/* SUBSCRIPTION Limited content access duration */
.limited-usage-wrap:has(input:focus) {
  @apply ring-2 ring-black/10 ring-offset-2 rounded;
}
.select-duration .select-wrap > div:nth-child(3) {
  @apply pt-[7px] pb-[8px];
}
.select-duration:not(.select-duration-full) .select-wrap > div:nth-child(3) {
  @apply bg-LightWood rounded-l-none;
}
.select-duration .select-wrap > div:nth-child(3) > div {
  @apply p-0 pl-2;
}
.select-duration .select-wrap > div svg {
  @apply stroke-LightWood;
}
/* SUBSCRIPTION Limited content access duration */

/* TAB COMPONENT STYLE */
.tab-items::before {
  @apply absolute content-[''] bottom-0 right-0 h-px w-full bg-black/5 transition-all duration-300;
}
.tab-items:has(.tab-item.fill)::before {
  @apply opacity-0;
}
.tab-item::before {
  @apply absolute content-[''] bottom-0 right-0 h-0.5 w-0 bg-black transition-all duration-300;
}
.tab-item.active::before {
  @apply w-full right-unset left-0;
}
.datatable-main tr .td-child span:first-letter {
  /* @apply uppercase; */
}
/* TAB COMPONENT STYLE */

/* CMS PAGE BAR */
.page-bar__item span::before,
.page-bar__item button::before {
  @apply absolute content-[''] right-0 top-unset bottom-0 h-0 w-1 bg-PrimaryYellow transition-all duration-300;
}
.page-bar__item span.active::before,
.page-bar__item button.active::before {
  @apply h-full bottom-unset top-0;
}
.cms-page-bar-content-wrap:has(:not(.page-bar)) {
  @apply block;
}
.cms-page-bar-content-wrap:has(.page-bar) {
  @apply grid grid-cols-[250px_1fr] gap-5 mt-30px;
}
.cms-form-card-wrap:has(.left-small-column:empty) {
  @apply grid-cols-1 1400:grid-cols-1;
}
.point-list-item:has(.left-part) {
  @apply flex-row flex-wrap;
}
.point-list-item .left-part {
  @apply w-[230px];
}
.point-list-item .right-part {
  @apply max-w-[calc(100%_-_230px)] pl-5 w-full;
}
.point-list-item:has(:not(.left-part)) .right-part {
  @apply pl-0 max-w-full;
}
.point-list-item .left-part,
.point-list-item .right-part {
  @apply flex flex-col gap-y-5;
}
.input-file-wrapper.two-clm {
  @apply grid grid-cols-[290px_1fr] gap-4;
}
/* CMS PAGE BAR */

/* COMMON SLIDERS */
.arrow-up {
  @apply relative -mt-20 z-2 pt-14 pointer-events-none;
}
.arrow-up .swiper {
  @apply pointer-events-none;
}
.arrow-up .swiper * {
  @apply pointer-events-auto;
}
.arrow-up-button .swiper-button:first-child {
  @apply !right-[180px];
}
.arrow-up-button .swiper-button:last-child {
  @apply !right-[120px];
}

[lang='es'] .arrow-up-button .swiper-button:first-child {
  @apply !right-[220px];
}
[lang='es'] .arrow-up-button .swiper-button:last-child {
  @apply !right-[160px];
}

.arrow-up .swiper-button:first-child,
.arrow-up .swiper-button:last-child {
  @apply pointer-events-auto select-none absolute flex items-center justify-center z-1 top-2.5 xl:top-0 cursor-pointer hover:opacity-80 right-0 left-unset w-8 xl:w-11 h-8 xl:h-11 bg-LightWood rounded-full;
}
.arrow-up .swiper-button svg {
  @apply w-4 xl:w-6 h-4 xl:h-6 text-PrimaryWood;
}
.arrow-up .swiper-button svg path {
  @apply stroke-1;
}
.arrow-up .swiper-button:first-child::after,
.arrow-up .swiper-button:last-child::after {
  @apply text-base stroke-2 stroke-PrimaryWood text-PrimaryWood;
}
.arrow-up .swiper-button.swiper-button-disabled {
  @apply opacity-100 bg-transparent cursor-not-allowed;
}
.arrow-up .swiper-button:first-child {
  @apply right-10 lg:right-16 rotate-180;
}
/* COMMON SLIDERS */

/* DICTIONARY SLIDER STYLE */
.dict-slider {
  @apply !pt-12 -mt-10;
}
.dict-slide.swiper-slide .video-wrap video {
  @apply relative aspect-square rounded-lg bg-LightGray;
}
.dict-slider .swiper-button-prev,
.dict-slider .swiper-button-next {
  @apply absolute flex items-center justify-center top-30px hover:bg-LightWood bottom-unset w-7 h-7 rounded-full transition-all duration-300;
}
.dict-slider .swiper-button-prev::before,
.dict-slider .swiper-button-next::before {
  @apply content-[''] w-2.5 h-2.5 rotate-45 border-l-2 border-b-2 border-solid border-l-black border-b-black bottom-0;
}
.dict-slider .swiper-button-prev::after,
.dict-slider .swiper-button-next::after {
  @apply w-4 h-0.5 absolute content-[''] bg-black right-1;
}
.dict-slider .swiper-button-prev {
  @apply left-unset right-10;
}
.dict-slider .swiper-button-next {
  @apply rotate-180 right-0;
}
/* DICTIONARY SLIDER STYLE */

/* COMMONUTY STYLE */
.topic-comment .topic-item-user-info.is-reported .topic-action {
  @apply mb-0 pb-0 border-0;
}
.topic-time::before {
  @apply w-1 h-1 bg-PrimaryWood rounded-full absolute content-[''] -left-2.5 top-1/2 -translate-y-1/2;
}
.topic-content__image video {
  @apply bg-white pointer-events-none w-full h-full object-contain border border-solid border-LightWood rounded;
}
.topic-name-time ~ div .topic-name-time .topic-option {
  @apply ml-0;
}
.topic-media-item video {
  @apply w-full h-full object-cover;
}
.reply-time::before {
  @apply w-1 h-1 bg-PrimaryWood rounded-full absolute content-[''] -left-2.5 top-1/2 -translate-y-1/2;
}
/* COMMONUTY STYLE */

/* RECORD WEBCAM */
.delete-overlay {
  @apply opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto cursor-pointer select-none active:scale-75 transition-all duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full flex items-center justify-center bg-white/70 backdrop-blur text-primary;
}
.image-drop-container {
  @apply flex flex-col h-full cursor-pointer transition-all duration-300 hover:bg-primary/5 items-center justify-center gap-y-2;
}
.record-webcam-recording.stop-recording > span::before {
  @apply absolute content-[''] rounded w-5 h-5 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-red-700;
}
/* RECORD WEBCAM */

/* CREATE / SELECT COURSE */
.checkbox-design::before {
  @apply absolute top-[3px] content-[''] w-2.5 h-1.5 border-r-2 border-t-2 border-solid border-white rotate-[135deg] opacity-0;
}
/* CREATE / SELECT COURSE */

/* AUTH STYLE */
.otp-wrapper {
  @apply flex flex-col gap-2 mb-5;
}
.otp-container {
  @apply flex justify-center gap-2;
}
.otp-container input {
  @apply border-2 border-black/5 bg-LightGray/50 focus:ring-black/50;
}
.form-qr-wrap img {
  @apply w-32 h-32 border border-solid border-LightGray rounded-5px p-3 mx-auto;
}
.login-with-app-title::before {
  @apply absolute content-[''] w-full h-px bg-PrimaryWood/20 left-0 top-1/2 -translate-y-1/2;
}
.login-app-icon svg {
  @apply w-[26px] h-[26px];
}
/* AUTH STYLE */

/*  */
.student-course-sort-video [data-media-player],
.student-course-sort-video video {
  @apply h-full w-full object-cover rounded-xl relative;
}
.student-course-tab-wrap .tab-item {
  @apply py-4;
}
.student-course-tab-wrap .tab-items {
  @apply justify-center;
}
.student-course-tab-wrap .tab-item::before {
  @apply bg-PrimaryYellow;
}
.student-course-tab-wrap .tab-content {
  @apply mt-0;
}
.student-course-banner::before {
  @apply w-full h-full z-1 absolute content-[''] top-0 left-0 bg-gradient-to-t from-black to-black/10;
}
.student-course-category > span:not(:first-child)::before {
  @apply absolute content-[''] w-1 h-1 bg-PrimaryYellow rounded-full top-1/2 -translate-y-1/2 -left-2.5;
}
.sign-connect-wrap .sign-connect-right .quiz-wrap::before {
  @apply absolute content-[''] w-full h-full left-0 top-0 bg-PrimaryWood/80;
}
.lesson-detail-banner::before {
  @apply absolute content-[''] top-0 left-0 w-full h-full bg-gradient-to-t from-black/80 rounded-t-10px;
}
.lesson-detail-video video {
  @apply w-full h-full object-cover rounded-10px;
}
.lesson-detail-video .video-overlay {
  @apply z-1 absolute bg-black/50 top-0 left-0 w-full h-full flex items-center justify-center rounded-10px;
}
.lesson-detail-video .video-overlay svg {
  @apply text-white w-10 h-10 cursor-pointer relative;
}
.lesson-detail-right p a,
.lesson-detail-right a {
  @apply text-PrimaryBlue text-base leading-normal inline-block hover:text-black transition-all duration-300;
}
.lesson-detail-quizbox::before {
  @apply absolute content-[''] w-full h-full left-0 top-0 bg-PrimaryWood/80 rounded-10px;
}
.lesson-detail-quizbox.attempted .content .button,
.lesson-detail-quizbox.attempted .content span {
  @apply static;
}
.lesson-detail-quizbox.attempted::before {
  @apply opacity-20;
}
.feedback-filed-item p:has(em, i) {
  @apply font-normal;
}
/*  */

.sticky-post-input-attach::before {
  @apply absolute content-[''] w-[calc(100%_+_10px)] h-[calc(100%_+_10px)] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2;
}
.container .container {
  @apply px-0;
}

.loader path {
  stroke-linecap: round;
}

mux-uploader::part(file-select-button) {
  @apply text-sm px-2 py-2 border border-dashed border-black/20;
}
mux-uploader::part(drop) {
  @apply min-h-[158px] !p-4 bg-white rounded-10px border border-dashed border-black/20;
}

.quiz_video .vds-controls,
.quiz_video .vds-start-duration {
  @apply hidden;
}

.mux-green mux-player {
  -webkit-text-fill-color: green;
}
.mux-black mux-player {
  -webkit-text-fill-color: black;
}

.mux-blue mux-player {
  -webkit-text-fill-color: blue;
}

.mux-red mux-player {
  -webkit-text-fill-color: red;
}
.mux-orange mux-player {
  -webkit-text-fill-color: orange;
}
.mux-yellow mux-player {
  -webkit-text-fill-color: yellow;
}
.mux-indigo mux-player {
  -webkit-text-fill-color: indigo;
}
.mux-violet mux-player {
  -webkit-text-fill-color: violet;
}
.mux-purple mux-player {
  -webkit-text-fill-color: purple;
}
.mux-pink mux-player {
  -webkit-text-fill-color: pink;
}
.mux-brown mux-player {
  -webkit-text-fill-color: brown;
}

.mux-white mux-player {
  -webkit-text-fill-color: white;
}

mux-player::part(control-bar) {
  -webkit-text-fill-color: #ffffff;
}
mux-player::part(menu-item) {
  -webkit-text-fill-color: #ffffff;
}
mux-player::part(playback-rate) {
  -webkit-text-fill-color: #ffffff;
}
mux-player::part(mute) {
  -webkit-text-fill-color: #000000;
  opacity: 0.5;
}

/*  */
.dictionary-filter-tab .button::before,
.dictionary-filter-tab button::before {
  @apply absolute content-[''] w-0 bg-black h-0.5 right-0 bottom-0 transition-all duration-300;
}
.dictionary-filter-tab .button.active::before,
.dictionary-filter-tab button.active::before {
  @apply w-full left-0 right-unset;
}

.support-accordion .accordion-content .inner img {
  @apply max-w-[400px] my-4 aspect-video;
}

.support-accordion .accordion-content .inner > img {
  @apply w-full h-[400px] object-cover rounded-2xl;
}

.dictionary-filter-tab .button::before,
.dictionary-filter-tab button::before {
  @apply absolute content-[''] w-0 bg-black h-0.5 right-0 bottom-0 transition-all duration-300;
}
.dictionary-filter-tab .button.active::before,
.dictionary-filter-tab button.active::before {
  @apply w-full left-0 right-unset;
}
/*  */

/*  */
.support-form .status-lable-wrap .status-label {
  @apply rounded-full;
}
