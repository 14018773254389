@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 668.48974609375px;
    stroke-dasharray: 668.48974609375px;
  }
  100% {
    stroke-dashoffset: 1336.9794921875px;
    stroke-dasharray: 668.48974609375px;
  }
}
@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 668.48974609375px;
    stroke-dasharray: 668.48974609375px;
  }
  100% {
    stroke-dashoffset: 1336.9794921875px;
    stroke-dasharray: 668.48974609375px;
  }
}
@-webkit-keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 1s linear 0s both,
    animate-svg-fill-1 0.5s linear 0.5s both;
  animation:
    animate-svg-stroke-1 1s linear 0s both,
    animate-svg-fill-1 0.5s linear 0.5s both;
}
@-webkit-keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 545.9950561523438px;
    stroke-dasharray: 545.9950561523438px;
  }
  100% {
    stroke-dashoffset: 1091.9901123046875px;
    stroke-dasharray: 545.9950561523438px;
  }
}
@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 545.9950561523438px;
    stroke-dasharray: 545.9950561523438px;
  }
  100% {
    stroke-dashoffset: 1091.9901123046875px;
    stroke-dasharray: 545.9950561523438px;
  }
}
@-webkit-keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
.svg-elem-2 {
  -webkit-animation:
    animate-svg-stroke-2 1s linear 0.3s both,
    animate-svg-fill-2 0.5s linear 1s both;
  animation:
    animate-svg-stroke-2 1s linear 0.3s both,
    animate-svg-fill-2 0.5s linear 1s both;
}
@-webkit-keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 456.0972595214844px;
    stroke-dasharray: 456.0972595214844px;
  }
  100% {
    stroke-dashoffset: 912.1945190429688px;
    stroke-dasharray: 456.0972595214844px;
  }
}
@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 456.0972595214844px;
    stroke-dasharray: 456.0972595214844px;
  }
  100% {
    stroke-dashoffset: 912.1945190429688px;
    stroke-dasharray: 456.0972595214844px;
  }
}
@-webkit-keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
.svg-elem-3 {
  -webkit-animation:
    animate-svg-stroke-3 1s linear 0.6s both,
    animate-svg-fill-3 0.5s linear 1.5s both;
  animation:
    animate-svg-stroke-3 1s linear 0.6s both,
    animate-svg-fill-3 0.5s linear 1.5s both;
}
